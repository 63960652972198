body {
  background-image: linear-gradient(136deg, #0a1c46 10%, #74ebd5 90%);
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
}

.container {
  min-height: 100vh;
}

main {
  /*margin-top: 80px;*/
}

/*@media (max-width:599px) {
  main {
    margin-top: 0px !important;
  }
}*/

.topMain{
  margin-top: 80px !important;
}

header.header {
  top: 0;
  background-color: #0a1c46;
  z-index: 100;
}

header.header nav {
  max-width: 1200px;
  margin: auto;
  background-color: #0a1c46;
}

header.header nav .nav-item {
  color: #ffffff;
  height: 60px;
  line-height: 50px;
  font-weight: bold;
}

header.header nav .nav-item.active {
  border-bottom: 8px solid #28a745;
  font-weight: bold;
}

.nav-tabs {
  border: none;
}

form.form-register {
  min-height: 1000px;
  background-color: #ffffff;
  width: 1080px;
}

form.form-register div.tabpanel {
  font-family: 'Open Sans', sans-serif;
}

form.form-register .wizard-header {
  text-align: center;
  padding: 5px 30px 13px;
}

form.form-register .wizard-header h3.heading {
  font-size: 25px;
  font-weight: 900;
  color: #333333;
}

form.form-register .wizard-header p {
  color: #666;
  font-size: 14px;
  font-weight: 600;
}

form.form-login,
form.form-new-register,
form.form-info {
  background-color: #ffffff;
  width: 469px;
  min-height: 400px;
  margin: auto;
  color: #333333;
}

form.form-login .form-header,
form.form-new-register .form-header,
form.form-info .form-header {
  background-color: #3e4061;
  color: #ffffff;
}

form.form-login .form-content,
form.form-new-register .form-content,
form.form-info .form-content {
  width: 100%;
}

form.form-login a,
form.form-new-register a,
form.form-info a {
  color: #3e4061;
}

form.form-info a.btn.btn-info {
  color: #ffffff;
}

form.form-new-register {
  width: 991px;
}

#tabPostulaciones {
  background-color: #3e4061;
}

#tabPostulaciones li.nav-item {
  width: 25%;
  height: 60px;
  padding: 0;
}

#tabPostulaciones li.nav-item a {
  height: 100%;
  width: 100%;
  padding: 0;
  color: #ffffff;
}

#tabPostulaciones li.nav-item a .step-icon {
  background-color: #cccccc;
  color: #333333;
  border: none;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

#tabPostulaciones li.nav-item a.active, #tabPostulaciones li.nav-item a.checked {
  font-weight: bold;
}

#tabPostulaciones li.nav-item a.active .step-icon, #tabPostulaciones li.nav-item a.checked .step-icon {
  background-color: #28a745;
  color: #ffffff;
}

#tabPostulaciones li.nav-item a.active {
  color: #3e4061;
}

@media (max-width: 991.98px) {
  #tabPostulaciones li.nav-item {
    width: 100%;
  }
  #tabPostulaciones li.nav-item a {
    padding-left: 15px;
  }
}

select {
  height: 40px;
}

.form-holder {
  margin: 10px 0;
}

.form-holder legend {
  font-size: 15px;
  font-weight: 400;
}

.form-holder input,
.form-holder textarea {
  width: 100%;
}

.form-holder .caja-select {
  border: 1px solid #d9d9d9;
  height: 40px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.form-holder .caja-select .zmdi {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}

.form-holder select {
  width: 100%;
  background: transparent;
  border: none;
}

.form-holder select:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  color: #53c7e0;
}

#condiciones_lab .wizard-body ul li {
  margin-bottom: 15px;
}

.btn-next,
.btn-previous {
  width: 50px;
  height: 50px;
  font-size: 25px;
}

.list-postul {
  background-color: #ffffff;
  min-height: 500px;
}

.list-postul .list-postul-header {
  background-color: #3e4061;
  color: #ffffff;
}

.list-postul .list-postul-content {
  /*max-height: 80vh;*/
  overflow: auto;
  overflow-x: hidden;
}

.list-postul .list-postul-content .div-search input {
  height: 40px;
  padding-left: 15px;
  border: 1px solid lightgrey;
}

.list-postul .list-postul-content .div-search button {
  height: 40px;
  /*top: 0; */
  right: 16px;
  border-radius: 0 5px 5px 0;
  bottom: 0;
}

.list-postul .list-postul-content table th,
.list-postul .list-postul-content table td {
  text-align: left;
  padding: .25rem !important;
  font-size: 14px !important;
}

.search-false p {
  color: #3e4061;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 50px;
}

input[readonly],
textarea[readonly],
.form-control[readonly] {
  background-color: #ffffff;
  border: none;
  color: #333333;
}

textarea[readonly] {
  height: 100%;
}

.hoja-vida .btn-reunion {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

#reunion_agendada {
  margin-top: 50px;
}

#modal-terminos-login .modal-header,
#modal-terminos .modal-header {
  background-color: #3e4061;
  color: #ffffff;
  font-weight: bold;
}

#modal-terminos-login .modal-body ul li,
#modal-terminos .modal-body ul li {
  margin-bottom: 15px;
}

#modal-terminos-login .modal-footer,
#modal-terminos .modal-footer {
  border: none;
}

@media only screen and (min-width: 1199.99px) {
  .list-postul {
    width: 1200px;
  }
}

@media only screen and (max-width: 1199.98px) {
  form.form-new-register,
  form.form-register {
    width: 991px;
  }
  .list-postul {
    width: 98vw;
  }
}

@media only screen and (max-width: 991.98px) {
  form.form-new-register,
  form.form-login,
  form.form-register,
  .list-postul {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  form.form-info,
  .list-postul {
    width: 98vw;
  }
}
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=styles.css.map */
.card-mensajes {
  flex-direction: row;    
}

@media (max-width: 500px) {
  .card-mensajes {
      flex-direction: column;    
  }
}

.rbt-input-hint {
  margin-top: -9px;
  display: none !important;
}

.boton-salir{
  margin-top: -15px;
  float: right;
  color: #28a745;
  cursor: pointer;
  font-size: 20px;
}

.boton-salir:hover{
  color: #218838;
}

.chip{
  padding: 3px 5px;
  border-radius: 10px;
  background-color: #28a745;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
}

.chip label {
  margin-bottom: 0px;
  cursor: pointer;
}

@media (min-width: 1200px){
  .container {
    width: 90% !important;
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
  flex-wrap: wrap !important;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fff;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.badge-no-inscrito{
  color: #fff;
  background-color: #FF5252;
}

.badge-iniciado{
  color: #fff;
  background-color: #512DA8;
}

